.project_title {
  color: var(--orange-red);
  text-transform: uppercase;
  font-size: 1.1rem;
}

.project_link {
  position: relative;
  text-decoration: none;
  color: var(--dark-blue);
}

.project_link:hover {
  color: var(--dark-blue);
  text-decoration: none;
}
