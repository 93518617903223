* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --light-blue: #e0f1f8;
  --dark-blue: #083966;
  --orange-red: #ef4a17;
  --light-gray: #dcdcdc;
  --dark-gray: #1a1a1a;
  --light-green: #c0f7db;
  --green: #3cb878;
  --dark-green: #086644;
  --white: #ffffff;
}

html {
  font-size: 100%;
}

body {
  position: relative;
  font-family: 'Mukta', sans-serif;
  font-weight: 300;
  overflow-y: scroll;
}

button:focus {
  outline: none;
}

.page-heading {
  font-weight: 800;
  color: var(--dark-blue);
}

.heading {
  font-weight: 700;
  color: var(--dark-blue);
}

.link {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 4px solid rgba(0,0,0,0);
  font-weight: 700;
  color: var(--dark-blue);
  transition: all 0.4s ease;
}

.active .link:hover {
  opacity: 1;
}

.link:hover,
.link:focus,
.link:active {
  border-bottom-color: rgba(8, 57, 102, 0.5);
  color: var(--dark-blue);
  opacity: 0.5;
}

.link:focus {
  outline: none;
}

.section-divider {
  margin-bottom: 72px;
}

/* SVG icons */
.svg-icon {
  width: 30px;
  height: 30px;
  fill: var(--dark-blue);
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.svg-icon:hover {
  opacity: 1;
}

.svg-arrow {
  width: 50px;
  height: 50px;
  fill: var(--orange-red);
}

.svg-arrow:hover {
  opacity: 0.8;
}

.text-gray {
  color: #083966;
  opacity: 0.6;
}

.separator {
  color: #083966;
  opacity: 0.3;
}

/**
  * TOOLTIP
**/

/* TOP */

.skills-tooltip {
  position: relative;
  padding: 12px 24px;
  color: var(--dark-blue);
  transition: all 0.3s ease;
}

/* TOOLTIP BOTTOM TRIANGLE */
.skills-tooltip:before {
  content: "";
  position: absolute;
  opacity: 0;
  left: 50%;
  top: 10px;

  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #f8f8f8 transparent transparent transparent;
  width: 0;
  height: 0;

  pointer-events: none;
  transform: translate3d(-50%, 0%, 0);
  transition: all 0.3s ease;
}

/* TOOLTIP BUBBLE */
.skills-tooltip:after {
  position: absolute;
  content: attr(data-tooltip);
  left: 50%;
  top: 0;

  border-radius: 4px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 300px;
  padding: 8px 12px;

  font-family: 'Mukta', sans-serif;
  text-transform: none;
  font-size: 14px;
  color: var(--dark-blue);
  background: #f8f8f8;
  pointer-events: none;

  opacity: 0;
  transform: translate3d(-50%,0%,0);
  transition: all 0.3s ease;
}

.skills-tooltip:hover {
  opacity: 0.95;
}

/* TOOLTIP FADEIN AND TRANSLATION */
.skills-tooltip:hover:before,
.skills-tooltip:hover:after {
  opacity: 1;
}

.skills-tooltip:before {
  transform: translate3d(-35%, calc(-100% - 18px), 0);
}

.skills-tooltip:after {
  transform: translate3d(-35%, calc(-100% - 16px), 0);
}