.skills {
  margin-bottom: 80px;
}

.square,
.circle,
.rectangle_react,
.rectangle_node,
.rectangle_mongo {
  font-family: 'Roboto Mono', monospace;
}

.square {
  width: 150px;
  height: 150px;
  background: var(--light-gray);
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

.square:hover {
  transform: scale(1.1);
}

.html_tag {
  font-size: 1.2rem;
  color: var(--dark-blue);
}

.circle {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  background: var(--orange-red);
}

.circle.bouncing {
  animation: bounce 0.8s cubic-bezier(.5,0.05,1,.5);
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.css_tag {
  font-size: 1.2rem;
  color: var(--white);
}

.button_wrapper {
  position: relative;
}

.stop_button {
  position: absolute;
  left: 12%;
  bottom: -70px;
  border: 2px solid var(--orange-red);
  min-width: 160px;
  padding: 4px 20px;
  color: var(--orange-red);
  font-weight: 700;
  background-color: var(--white);
  transition: all 0.3s ease;
}

.stop_button:hover,
.stop_button:focus,
.stop_button:active {
  border: 2px solid var(--dark-blue);
  color: var(--dark-blue);
}

.rectangle_react,
.rectangle_node,
.rectangle_mongo {
  transform: translateX(0);
  transition: all 0.3s;
  font-size: 1.2rem;
  height: 60px;
}

.rectangle_react:hover,
.rectangle_node:hover,
.rectangle_mongo:hover {
  transform: translateX(10px);
}

.rectangle_react {
  width: 110px;
  margin-top: 2rem;
  background: var(--light-green);
  color: var(--dark-green);
}

.rectangle_node {
  width: 160px;
  background: var(--green);
  color: var(--white);
}

.rectangle_mongo {
  width: 210px;
  background: var(--dark-green);
  color: var(--white);
}

@keyframes bounce {
  from {
    transform: translate3d(0, -40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 578px) {
  .skills {
    margin-bottom: 142px;
  }

  .square {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .stop_button {
    left: 50%;
    transform: translateX(-50%);
  }

  .rectangle_react {
    width: 150px;
    height: 70px;
    margin-top: 0;
  }

  .rectangle_node {
    width: 190px;
    height: 70px;
  }

  .rectangle_mongo {
    width: 230px;
    height: 70px;
  }
}

@media (max-width: 577px) {
  .square {
    width: 120px;
    height: 120px;
  }

  .circle {
    width: 140px;
    height: 140px;
  }

  .stop_button {
    left: 7%;
    min-width: 120px;;
  }
}