.bg_light_blue {
  background: var(--light-blue);
}

.arrow_wrap {
  position: relative;
}

.contact_arrow_up {
  display: none;
}

.contact_title {
  font-size: 3rem;
}

.contact_content_name {
  margin-bottom: -4px;
  font-size: 1.3rem;
}

.contact_email {
  padding: 20px 0;
}

.contact_email a {
  font-weight: 400;
}

@media (min-width: 992px) {
  .bg_light_blue {
    background: linear-gradient(
      to right,
      var(--white) 0%,
      var(--white) 38%,
      var(--light-blue) 38%,
      var(--light-blue) 100%)
    ;
  }

  .contact_title {
    margin-left: 16%;
    font-size: 4rem;
  }

  .contact_content {
    margin-left: 40%;
  }

  .contact_arrow_up {
    display: inline-block;
    position: absolute;
    top: 80px;
    right: 0;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    cursor: pointer;
  }
}

@media (min-width: 1400px) {
  .bg_light_blue {
    background: linear-gradient(
      to right,
      var(--white) 0%,
      var(--white) 42%,
      var(--light-blue) 42%,
      var(--light-blue) 100%)
    ;
  }
}

@keyframes floating {
  from { transform: rotate(180deg) translate(0,  0px); }
  65%  { transform: rotate(180deg) translate(0, 15px); }
  to   { transform: rotate(180deg) translate(0, -0px); }
}