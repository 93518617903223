* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --light-blue: #e0f1f8;
  --dark-blue: #083966;
  --orange-red: #ef4a17;
  --light-gray: #dcdcdc;
  --dark-gray: #1a1a1a;
  --light-green: #c0f7db;
  --green: #3cb878;
  --dark-green: #086644;
  --white: #ffffff;
}

html {
  font-size: 100%;
}

body {
  position: relative;
  font-family: 'Mukta', sans-serif;
  font-weight: 300;
  overflow-y: scroll;
}

button:focus {
  outline: none;
}

.page-heading {
  font-weight: 800;
  color: #083966;
  color: var(--dark-blue);
}

.heading {
  font-weight: 700;
  color: #083966;
  color: var(--dark-blue);
}

.link {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 4px solid rgba(0,0,0,0);
  font-weight: 700;
  color: #083966;
  color: var(--dark-blue);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.active .link:hover {
  opacity: 1;
}

.link:hover,
.link:focus,
.link:active {
  border-bottom-color: rgba(8, 57, 102, 0.5);
  color: #083966;
  color: var(--dark-blue);
  opacity: 0.5;
}

.link:focus {
  outline: none;
}

.section-divider {
  margin-bottom: 72px;
}

/* SVG icons */
.svg-icon {
  width: 30px;
  height: 30px;
  fill: #083966;
  fill: var(--dark-blue);
  opacity: 0.6;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.svg-icon:hover {
  opacity: 1;
}

.svg-arrow {
  width: 50px;
  height: 50px;
  fill: #ef4a17;
  fill: var(--orange-red);
}

.svg-arrow:hover {
  opacity: 0.8;
}

.text-gray {
  color: #083966;
  opacity: 0.6;
}

.separator {
  color: #083966;
  opacity: 0.3;
}

/**
  * TOOLTIP
**/

/* TOP */

.skills-tooltip {
  position: relative;
  padding: 12px 24px;
  color: #083966;
  color: var(--dark-blue);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* TOOLTIP BOTTOM TRIANGLE */
.skills-tooltip:before {
  content: "";
  position: absolute;
  opacity: 0;
  left: 50%;
  top: 10px;

  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #f8f8f8 transparent transparent transparent;
  width: 0;
  height: 0;

  pointer-events: none;
  -webkit-transform: translate3d(-50%, 0%, 0);
          transform: translate3d(-50%, 0%, 0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* TOOLTIP BUBBLE */
.skills-tooltip:after {
  position: absolute;
  content: attr(data-tooltip);
  left: 50%;
  top: 0;

  border-radius: 4px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 300px;
  padding: 8px 12px;

  font-family: 'Mukta', sans-serif;
  text-transform: none;
  font-size: 14px;
  color: #083966;
  color: var(--dark-blue);
  background: #f8f8f8;
  pointer-events: none;

  opacity: 0;
  -webkit-transform: translate3d(-50%,0%,0);
          transform: translate3d(-50%,0%,0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.skills-tooltip:hover {
  opacity: 0.95;
}

/* TOOLTIP FADEIN AND TRANSLATION */
.skills-tooltip:hover:before,
.skills-tooltip:hover:after {
  opacity: 1;
}

.skills-tooltip:before {
  -webkit-transform: translate3d(-35%, calc(-100% - 18px), 0);
          transform: translate3d(-35%, calc(-100% - 18px), 0);
}

.skills-tooltip:after {
  -webkit-transform: translate3d(-35%, calc(-100% - 16px), 0);
          transform: translate3d(-35%, calc(-100% - 16px), 0);
}

.AboutMe_bg_light_blue__soZe_ {
  background: var(--light-blue);
}

.AboutMe_arrow_wrap__204-z {
  position: relative;
}

.AboutMe_aboutMe_title__3_ca4 {
  padding-top: 4rem;
  font-size: 3rem;
}

.AboutMe_about_content__lVC1D {
  padding: 2.5rem 0;
  font-size: 1rem;
}

.AboutMe_about_arrow_down__9f_UC {
  display: none;
}

@media (min-width: 992px) {
  .AboutMe_bg_light_blue__soZe_ {
    background: -webkit-linear-gradient(
      left,
      var(--light-blue) 0%,
      var(--light-blue) 62%,
      var(--white) 62%,
      var(--white) 100%)
    ;
    background: linear-gradient(
      to right,
      var(--light-blue) 0%,
      var(--light-blue) 62%,
      var(--white) 62%,
      var(--white) 100%)
    ;
  }

  .AboutMe_about__3HwJB {
    margin-left: 50%;
  }

  .AboutMe_aboutMe_title__3_ca4 {
    padding-top: 4.5rem;
    font-size: 5rem;
  }

  .AboutMe_about_content__lVC1D {
    margin-left: 68%;
    padding: 0;
    font-size: 1.2rem;
  }

  .AboutMe_about_arrow_down__9f_UC {
    display: inline-block;
    position: absolute;
    bottom: 80px;
    left: 0;
    -webkit-animation-name: AboutMe_floating__1oSrD;
            animation-name: AboutMe_floating__1oSrD;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    cursor: pointer;
  }
}

@media (min-width: 1400px) {
  .AboutMe_bg_light_blue__soZe_ {
    background: -webkit-linear-gradient(
      left,
      var(--light-blue) 0%,
      var(--light-blue) 58%,
      var(--white) 58%,
      var(--white) 100%)
    ;
    background: linear-gradient(
      to right,
      var(--light-blue) 0%,
      var(--light-blue) 58%,
      var(--white) 58%,
      var(--white) 100%)
    ;
  }
}

@-webkit-keyframes AboutMe_floating__1oSrD {
  from { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
  65%  { -webkit-transform: translate(0, 15px); transform: translate(0, 15px); }
  to   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }
}

@keyframes AboutMe_floating__1oSrD {
  from { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
  65%  { -webkit-transform: translate(0, 15px); transform: translate(0, 15px); }
  to   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }
}
.NavItems_nav_items__2yQdo {
  font-size: 1rem;
}
.Skills_skills__3J2r0 {
  margin-bottom: 80px;
}

.Skills_square__19dk0,
.Skills_circle__3j0VE,
.Skills_rectangle_react__ZuJGW,
.Skills_rectangle_node__Wz-x3,
.Skills_rectangle_mongo__3GMGY {
  font-family: 'Roboto Mono', monospace;
}

.Skills_square__19dk0 {
  width: 150px;
  height: 150px;
  background: var(--light-gray);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Skills_square__19dk0:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Skills_html_tag__1U6-_ {
  font-size: 1.2rem;
  color: var(--dark-blue);
}

.Skills_circle__3j0VE {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  background: var(--orange-red);
}

.Skills_circle__3j0VE.Skills_bouncing__ejpX3 {
  -webkit-animation: Skills_bounce__2B8Y9 0.8s cubic-bezier(.5,0.05,1,.5);
          animation: Skills_bounce__2B8Y9 0.8s cubic-bezier(.5,0.05,1,.5);
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.Skills_css_tag__2XWJr {
  font-size: 1.2rem;
  color: var(--white);
}

.Skills_button_wrapper__3m9OT {
  position: relative;
}

.Skills_stop_button__2RNTW {
  position: absolute;
  left: 12%;
  bottom: -70px;
  border: 2px solid var(--orange-red);
  min-width: 160px;
  padding: 4px 20px;
  color: var(--orange-red);
  font-weight: 700;
  background-color: var(--white);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Skills_stop_button__2RNTW:hover,
.Skills_stop_button__2RNTW:focus,
.Skills_stop_button__2RNTW:active {
  border: 2px solid var(--dark-blue);
  color: var(--dark-blue);
}

.Skills_rectangle_react__ZuJGW,
.Skills_rectangle_node__Wz-x3,
.Skills_rectangle_mongo__3GMGY {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.2rem;
  height: 60px;
}

.Skills_rectangle_react__ZuJGW:hover,
.Skills_rectangle_node__Wz-x3:hover,
.Skills_rectangle_mongo__3GMGY:hover {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}

.Skills_rectangle_react__ZuJGW {
  width: 110px;
  margin-top: 2rem;
  background: var(--light-green);
  color: var(--dark-green);
}

.Skills_rectangle_node__Wz-x3 {
  width: 160px;
  background: var(--green);
  color: var(--white);
}

.Skills_rectangle_mongo__3GMGY {
  width: 210px;
  background: var(--dark-green);
  color: var(--white);
}

@-webkit-keyframes Skills_bounce__2B8Y9 {
  from {
    -webkit-transform: translate3d(0, -40px, 0);
            transform: translate3d(0, -40px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes Skills_bounce__2B8Y9 {
  from {
    -webkit-transform: translate3d(0, -40px, 0);
            transform: translate3d(0, -40px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 578px) {
  .Skills_skills__3J2r0 {
    margin-bottom: 142px;
  }

  .Skills_square__19dk0 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .Skills_stop_button__2RNTW {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .Skills_rectangle_react__ZuJGW {
    width: 150px;
    height: 70px;
    margin-top: 0;
  }

  .Skills_rectangle_node__Wz-x3 {
    width: 190px;
    height: 70px;
  }

  .Skills_rectangle_mongo__3GMGY {
    width: 230px;
    height: 70px;
  }
}

@media (max-width: 577px) {
  .Skills_square__19dk0 {
    width: 120px;
    height: 120px;
  }

  .Skills_circle__3j0VE {
    width: 140px;
    height: 140px;
  }

  .Skills_stop_button__2RNTW {
    left: 7%;
    min-width: 120px;;
  }
}
.Project_project_title__2t3ya {
  color: var(--orange-red);
  text-transform: uppercase;
  font-size: 1.1rem;
}

.Project_project_link__83YjT {
  position: relative;
  text-decoration: none;
  color: var(--dark-blue);
}

.Project_project_link__83YjT:hover {
  color: var(--dark-blue);
  text-decoration: none;
}

.Projects_project_section__1Ua8V {
  margin-bottom: 24px;
}
.Contact_bg_light_blue__2rzMY {
  background: var(--light-blue);
}

.Contact_arrow_wrap__247uk {
  position: relative;
}

.Contact_contact_arrow_up__2dPCd {
  display: none;
}

.Contact_contact_title__2ub7Q {
  font-size: 3rem;
}

.Contact_contact_content_name__3_6U6 {
  margin-bottom: -4px;
  font-size: 1.3rem;
}

.Contact_contact_email__2upnE {
  padding: 20px 0;
}

.Contact_contact_email__2upnE a {
  font-weight: 400;
}

@media (min-width: 992px) {
  .Contact_bg_light_blue__2rzMY {
    background: -webkit-linear-gradient(
      left,
      var(--white) 0%,
      var(--white) 38%,
      var(--light-blue) 38%,
      var(--light-blue) 100%)
    ;
    background: linear-gradient(
      to right,
      var(--white) 0%,
      var(--white) 38%,
      var(--light-blue) 38%,
      var(--light-blue) 100%)
    ;
  }

  .Contact_contact_title__2ub7Q {
    margin-left: 16%;
    font-size: 4rem;
  }

  .Contact_contact_content__3LNY2 {
    margin-left: 40%;
  }

  .Contact_contact_arrow_up__2dPCd {
    display: inline-block;
    position: absolute;
    top: 80px;
    right: 0;
    -webkit-animation-name: Contact_floating__L36rO;
            animation-name: Contact_floating__L36rO;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    cursor: pointer;
  }
}

@media (min-width: 1400px) {
  .Contact_bg_light_blue__2rzMY {
    background: -webkit-linear-gradient(
      left,
      var(--white) 0%,
      var(--white) 42%,
      var(--light-blue) 42%,
      var(--light-blue) 100%)
    ;
    background: linear-gradient(
      to right,
      var(--white) 0%,
      var(--white) 42%,
      var(--light-blue) 42%,
      var(--light-blue) 100%)
    ;
  }
}

@-webkit-keyframes Contact_floating__L36rO {
  from { -webkit-transform: rotate(180deg) translate(0,  0px); transform: rotate(180deg) translate(0,  0px); }
  65%  { -webkit-transform: rotate(180deg) translate(0, 15px); transform: rotate(180deg) translate(0, 15px); }
  to   { -webkit-transform: rotate(180deg) translate(0, -0px); transform: rotate(180deg) translate(0, -0px); }
}

@keyframes Contact_floating__L36rO {
  from { -webkit-transform: rotate(180deg) translate(0,  0px); transform: rotate(180deg) translate(0,  0px); }
  65%  { -webkit-transform: rotate(180deg) translate(0, 15px); transform: rotate(180deg) translate(0, 15px); }
  to   { -webkit-transform: rotate(180deg) translate(0, -0px); transform: rotate(180deg) translate(0, -0px); }
}
