.bg_light_blue {
  background: var(--light-blue);
}

.arrow_wrap {
  position: relative;
}

.aboutMe_title {
  padding-top: 4rem;
  font-size: 3rem;
}

.about_content {
  padding: 2.5rem 0;
  font-size: 1rem;
}

.about_arrow_down {
  display: none;
}

@media (min-width: 992px) {
  .bg_light_blue {
    background: linear-gradient(
      to right,
      var(--light-blue) 0%,
      var(--light-blue) 62%,
      var(--white) 62%,
      var(--white) 100%)
    ;
  }

  .about {
    margin-left: 50%;
  }

  .aboutMe_title {
    padding-top: 4.5rem;
    font-size: 5rem;
  }

  .about_content {
    margin-left: 68%;
    padding: 0;
    font-size: 1.2rem;
  }

  .about_arrow_down {
    display: inline-block;
    position: absolute;
    bottom: 80px;
    left: 0;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    cursor: pointer;
  }
}

@media (min-width: 1400px) {
  .bg_light_blue {
    background: linear-gradient(
      to right,
      var(--light-blue) 0%,
      var(--light-blue) 58%,
      var(--white) 58%,
      var(--white) 100%)
    ;
  }
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }
}